'use client';

import React, { useState } from 'react';
import { LocalStorage } from '@mentimeter/storage';
import Cookies from '@mentimeter/cookies';
import type { ConfidenceFeatureOverrides } from './ConfidenceOverridesContext';
import { ConfidenceOverridesContext } from './ConfidenceOverridesContext';
import { CONFIDENCE_OVERRIDES_KEY } from './constants';

function migrateLocalStorageToCookie() {
  const localStorageOverrides =
    LocalStorage.getJSONItem(CONFIDENCE_OVERRIDES_KEY) ?? {};

  Cookies.setJSON({
    type: 'functional',
    name: CONFIDENCE_OVERRIDES_KEY,
    value: localStorageOverrides,
    options: {
      expires: 365,
      sameSite: 'lax',
    },
  });
}

export function getConfidenceOverrides(): ConfidenceFeatureOverrides {
  if (Cookies.getJSON(CONFIDENCE_OVERRIDES_KEY) === undefined) {
    migrateLocalStorageToCookie();
  }

  return LocalStorage.getJSONItem(CONFIDENCE_OVERRIDES_KEY) ?? {};
}

function setConfidenceOverrides(overrides: ConfidenceFeatureOverrides) {
  LocalStorage.setJSONItem({
    type: 'functional',
    key: CONFIDENCE_OVERRIDES_KEY,
    value: overrides,
  });
  Cookies.setJSON({
    type: 'functional',
    name: CONFIDENCE_OVERRIDES_KEY,
    value: overrides,
    options: {
      expires: 365,
      sameSite: 'lax',
    },
  });
}

export const ConfidenceOverridesProvider = ({
  children,
  overridesEnabled,
  experimentOverrides,
}: {
  children: React.ReactNode;
  overridesEnabled: boolean;
  experimentOverrides?:
    | {
        manualOverrides: ConfidenceFeatureOverrides;
        experiments: Record<string, readonly string[]>;
      }
    | undefined;
}) => {
  const [localStorageOverrides, setChangedFlags] =
    React.useState<ConfidenceFeatureOverrides>({});
  const [hasLoadedFromStorage, setHasLoadedFromStorage] = useState(false);

  const { manualOverrides, experiments } = experimentOverrides ?? {};

  // Initiate from localstorage
  React.useEffect(() => {
    const storedOverrides: ConfidenceFeatureOverrides =
      getConfidenceOverrides();
    setChangedFlags(storedOverrides);
    setHasLoadedFromStorage(true);
  }, []);

  const overrides = React.useMemo(() => {
    if (
      !overridesEnabled ||
      !manualOverrides ||
      !experiments ||
      Object.keys(experiments).length === 0
    ) {
      return localStorageOverrides;
    }

    const filteredManualOverrides = Object.fromEntries(
      Object.entries(manualOverrides).filter(([key, value]) =>
        experiments[key]?.includes(value),
      ),
    );

    return { ...localStorageOverrides, ...filteredManualOverrides };
  }, [overridesEnabled, manualOverrides, experiments, localStorageOverrides]);

  // Sync it up
  React.useEffect(() => {
    if (!hasLoadedFromStorage) return;

    setConfidenceOverrides(overrides);
  }, [hasLoadedFromStorage, overrides]);

  const value = React.useMemo(
    () => ({
      values: overrides,
      set: (flagName: string, nextValue: string) => {
        setChangedFlags((current) => ({ ...current, [flagName]: nextValue }));
      },
      reset: (flagName: string) => {
        setChangedFlags(({ ...copy }) => {
          delete copy[flagName];
          return copy;
        });
      },
      clearAll: () => {
        setChangedFlags({});
      },
      fromTreatments: (treatments: Record<string, string>) => {
        return Object.keys(treatments).reduce((prev, curr) => {
          return {
            ...prev,
            [curr]: overrides[curr] ?? treatments[curr],
          };
        }, {}) as any;
      },
    }),
    [overrides],
  );

  return (
    <ConfidenceOverridesContext.Provider value={value}>
      {children}
    </ConfidenceOverridesContext.Provider>
  );
};
