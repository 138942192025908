import type { EvaluationContext } from '@openfeature/core';

interface DeviceEvaluationContextParams {
  userAgent: string;
}

export interface DeviceEvaluationContext {
  is_mobile: boolean;
}

export function getDeviceEvaluationContext({
  userAgent,
}: DeviceEvaluationContextParams): DeviceEvaluationContext & EvaluationContext {
  return {
    is_mobile: isHandheld(userAgent),
  };
}

function isHandheld(userAgent: string): boolean {
  return Boolean(
    userAgent.match(/Android/i) ||
      userAgent.match(/webOS/i) ||
      userAgent.match(/iPhone/i) ||
      userAgent.match(/iPod/i) ||
      userAgent.match(/iPad/i) ||
      userAgent.match(/BlackBerry/),
  );
}
