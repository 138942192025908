'use client';
// This import must go at the top
import '../api/http-setup';
import { ConfidenceProvider } from '../confidence/ConfidenceProvider';
import AppProvider from '../features/App';
import NoScript from '../features/NoScript';
import { TrackingProvider } from '../utils/tracker';
import { FelaProvider } from './FelaProvider';

export default function Providers({ children }: { children: React.ReactNode }) {
  return (
    <FelaProvider>
      <AppProvider>
        <NoScript />
        <TrackingProvider>
          <ConfidenceProvider>{children}</ConfidenceProvider>
        </TrackingProvider>
      </AppProvider>
    </FelaProvider>
  );
}
