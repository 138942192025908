'use client';

import {
  getDeviceEvaluationContext,
  getVoterEvaluationContext,
  getEnvironmentEvaluationContext,
} from '@mentimeter/flags/openfeature/server';
import { ConfidenceClientProvider } from '@mentimeter/flags/confidence/client';
import { Box } from '@mentimeter/ragnar-ui/box';
import { LocalStorage } from '@mentimeter/storage';
import { LoaderIcon } from '@mentimeter/ragnar-visuals';
import { useIdentifier } from '../utils/useIdentifier';

const CONFIDENCE_CLIENT_TOKEN = process.env
  .NEXT_PUBLIC_CONFIDENCE_CLIENT_TOKEN as string;

export const ConfidenceProvider = ({ children }: React.PropsWithChildren) => {
  const identifier = useIdentifier();

  // This 3 condtions (isNode, typeof localStorage === 'undefined', !identifier)

  // The Confidence SDK cannot be run server side
  if (typeof window === 'undefined') return children;

  // If we don't have access to localStorage, Confidence does not work
  if (!LocalStorage.isSupported()) return children;

  // we don't want to assign voters to treatments before they've received their identifier
  if (!identifier) return <Loading />;

  return (
    <ConfidenceClientProvider
      ready={true}
      overridesEnabled={false}
      clientToken={CONFIDENCE_CLIENT_TOKEN}
      evaluationContext={{
        ...getDeviceEvaluationContext({
          userAgent: window.navigator.userAgent,
        }),
        ...getVoterEvaluationContext({ voterId: identifier }),
        ...getEnvironmentEvaluationContext({
          environment: process.env.NEXT_PUBLIC_ENVIRONMENT_NAME,
        }),
      }}
    >
      {children}
    </ConfidenceClientProvider>
  );
};

function Loading() {
  return (
    <Box
      height="100%"
      width="100%"
      justifyContent="center"
      alignItems="center"
      bg="bg"
      p={3}
    >
      <LoaderIcon />
    </Box>
  );
}
