import * as React from 'react';
import { Text } from '@mentimeter/ragnar-ui/text';
import { Link } from '@mentimeter/ragnar-ui/link';
import { Box } from '@mentimeter/ragnar-ui/box';

const NoScript = () => {
  return (
    <noscript style={{ width: '100%' }}>
      <Box
        width="100%"
        justifyContent="center"
        alignItems="center"
        p={3}
        bg="negative"
      >
        <Text
          fontSize={2}
          fontWeight="semiBold"
          textAlign="center"
          color="onNegative"
        >
          Please turn on Javascript
        </Text>
        <Text textAlign="center" my={1} color="onNegative">
          Your browser either doesn&apos;t support Javascript, or you&apos;ve
          turned it off.
        </Text>
        <Text textAlign="center" color="onNegative">
          To vote with Mentimeter, please make sure to turn Javascript on.&nbsp;
        </Text>
        <Link
          color="onNegative"
          textAlign="center"
          href="https://www.enable-javascript.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          How do I enable Javascript?
        </Link>
      </Box>
    </noscript>
  );
};

export default NoScript;
