import type { EvaluationContext } from '@openfeature/react-sdk';

interface VoterEvaluationContextParams {
  voterId: string | undefined;
}

export interface VoterEvaluationContext {
  voter_id: string;
}

export function getVoterEvaluationContext({
  voterId,
}: VoterEvaluationContextParams): VoterEvaluationContext & EvaluationContext {
  return {
    voter_id: voterId || '',
  };
}
