'use client';

import { useMemo } from 'react';
import {
  ClientProvider as OpenFeatureClientProvider,
  type OpenFeatureEvaluationContext,
} from '../../../openfeature';
import { ConfidenceOverridesProvider } from '../development-tools';
import { createConfidenceProvider } from './provider';

const IS_DEV = process.env.NODE_ENV === 'development';

export function ConfidenceClientProvider({
  ready,
  clientToken,
  evaluationContext,
  overridesEnabled,
  children,
}: {
  ready: boolean;
  clientToken: string | undefined;
  evaluationContext: OpenFeatureEvaluationContext;
  overridesEnabled: boolean;
  children: React.ReactNode;
}) {
  const shouldInitializeConfidence = !IS_DEV && ready && clientToken;

  const provider = useMemo(() => {
    if (!shouldInitializeConfidence) {
      return null;
    }

    return createConfidenceProvider(clientToken);
  }, [clientToken, shouldInitializeConfidence]);

  return (
    <ConfidenceOverridesProvider overridesEnabled={overridesEnabled}>
      <OpenFeatureClientProvider
        evaluationContext={evaluationContext}
        provider={provider}
      >
        {children}
      </OpenFeatureClientProvider>
    </ConfidenceOverridesProvider>
  );
}
